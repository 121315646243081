import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CardMediaComponent from '../../../common/cardMediaComponent/cardMediaComponent';
import bgimage from '../../../assets/images/bgEntrenadores.jpeg';
import { useParams, useHistory } from 'react-router';
import { LOADING_STATUS } from '../../../helpers/constants';
import Loading from '../../../common/Loading/Loading';
import { Box, ListItem } from '@material-ui/core';
import { fetchLandingProductInfo } from '../../products/singleProduct/SingleProductSlice';
import { fetchLandingProducts } from '../../products/main/ProductsSlice';

const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    avatar: {
        margin: theme.spacing(1),
        height: '250px',
        width: '250px',
    },
    heroContent: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: theme.spacing(8, 0, 6),
        placeItems: 'center',
    },
    backgroundImagen: {
        backgroundImage: `url(${bgimage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    description: {
        whiteSpace: 'pre-wrap',
        textAlign: 'center',
    },
    image: props => ({
        height: 500,
        margin: '10px 0',
        backgroundImage: `url(${props.image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        [theme.breakpoints.down('md')]: {
            height: 300,
        },
    }),
}));

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function SingleItem() {
    const dispatch = useDispatch();
    const { name } = useParams();
    const items = useSelector(state => state.products);
    const item = useSelector(state => state.singleProduct.product.productInfo);
    const loading = useSelector(
        state => state.singleProduct.fetchProductInfoStatus
    );
    const [products, setProducts] = useState();
    const classes = useStyles({ image: item.image });

    useEffect(() => {
        dispatch(fetchLandingProducts())
            .then(({ payload }) => {
                dispatch(
                    fetchLandingProductInfo(
                        payload.find(item => item.path === name)?.id
                    )
                );
            })
            .catch(err => console.log(err));
    }, [dispatch]);

    return loading === LOADING_STATUS.LOADING ? (
        <Loading />
    ) : (
        <React.Fragment>
            <CssBaseline />
            <main>
                <Container>
                    <Grid container spacing={3}>
                        {/* Información del producto */}
                        <Grid item xs={12} md={6}>
                            <Box className={classes.image}></Box>
                        </Grid>

                        {/* Selección de color y tamaño */}
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4" component="h1">
                                {item.name}
                            </Typography>
                            <Typography variant="h5" component="p">
                                Precio: ${item.clientPrice}
                            </Typography>
                            <Typography variant="h5" component="p">
                                {item.description}
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
                {/* <Container className={classes.cardGrid} maxWidth="md">
                    <Grid
                        container
                        spacing={4}
                        justifyContent="center"
                        alignContent="center"
                        alignItems="center"
                        direction="column"
                    >
                        <Grid item>
                            <Avatar
                                className={classes.avatar}
                                alt={`${item.name}`}
                                src={`${item.image}`}
                            />
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="h4"
                                align="center"
                                gutterBottom
                            >
                                {item.name}
                            </Typography>
                            <Typography
                                variant="h6"
                                align="center"
                                gutterBottom
                            >
                                {item.email}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                className={classes.description}
                                variant="h5"
                                gutterBottom
                            >
                                {item.description}
                            </Typography>
                        </Grid>
                    </Grid>
                </Container> */}
            </main>
        </React.Fragment>
    );
}
