import { useEffect, useRef, useState } from 'react';
import {
    Button,
    CircularProgress,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Checkbox,
    TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { Link, useParams } from 'react-router-dom';
import { LOADING_STATUS } from '../../../../helpers/constants';
import classNames from 'classnames';
import { renderDateValue } from '../../../../helpers/formHelpers';
import { AddNewElement } from '../../../../common/FormComponents/FormComponents';
import UploadDialog from '../../../../common/DocumentComponents/UploadDialog/UploadDialogPayment';
import { DoneAll } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import PaymentReview from '../../../../common/DocumentComponents/UploadDialog/PaymentReview';
import {
    fetchPaymentInfo,
    resetValues,
    updatePaymentInfo,
} from '../userPaymentInfoSlice';
import { formatInsertPayment } from '../utils';
import { fetchUserPayments } from '../userPaymentsSlice';

const useActionStyles = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

const useTableStyles = makeStyles(theme => ({
    noInfo: {
        color: theme.palette.grey[400],
        fontWeight: theme.typography.fontWeightBold,
        textAlign: 'center',
    },
    fullHeight: {
        height: 484,
    },
    tableFooter: {
        background: `linear-gradient(to top, white 0%, white calc(100% - 1px), ${theme.palette.grey[300]} calc(100% - 1px))`,
        position: 'sticky',
        bottom: 0,
    },
    tableHead: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        position: 'sticky',
        top: 0,
    },
    tableHeadCell: {
        color: 'white',
        fontWeight: theme.typography.fontWeightBold,
        textTransform: 'uppercase',
    },
    tableLink: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    },
    tableRoot: {
        borderRadius: theme.shape.borderRadius,
        maxHeight: 484,
        '& td, & th': {
            height: 40,
        },
        '& table': {
            borderCollapse: 'separate',
        },
    },
    btnUpd: {
        margin: theme.spacing(0, 2),
    },
    forUpdate: {
        display: 'flex',
        flexDirection: 'row-reverse',
        padding: theme.spacing(0, 0, 2, 0),
    },
}));

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
    const classes = useActionStyles();

    const handleFirstPageButtonClick = event => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = event => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                <FirstPageIcon />
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                <LastPageIcon />
            </IconButton>
        </div>
    );
};

const PaymentTable = ({ rows = [], status, edit, data }) => {
    const { id } = useParams();
    const permissions = useSelector(state => state.user.userData.role);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [rowOpen, setRowOpen] = useState();
    const [open, setOpen] = useState(false);
    const [files, setFiles] = useState([]);
    const classes = useTableStyles();
    const rowsLength = rows.length;
    const prevRows = useRef(rows);
    const paymentStatus = useSelector(
        state => state.catalogs.catalogs.paymentStatus
    );
    const currentUser = useSelector(state => state.user.userData.id);
    const paymentTypes = useSelector(
        state => state.catalogs.catalogs.paymentTypes
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (prevRows.current !== rowsLength) {
            prevRows.current = rowsLength;
            setPage(0);
        }
    }, [rowsLength]);

    useEffect(() => {
        if (files.length !== rows.length) setFiles([...rows]);
    }, [rows, files, setFiles]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleFileChange = file => {
        setFiles(prev => [...prev, file]);
        setOpen(false);
    };

    return (
        <div>
            <TableContainer
                component={Paper}
                className={classNames(classes.tableRoot, {
                    [classes.fullHeight]:
                        status === LOADING_STATUS.LOADING || rows.length === 0,
                })}
            >
                <Table size="small" aria-label="a dense table">
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell className={classes.tableHeadCell}>
                                Pago
                            </TableCell>
                            <TableCell
                                align="center"
                                className={classes.tableHeadCell}
                            >
                                Fecha de vencimiento
                            </TableCell>
                            <TableCell
                                align="center"
                                className={classes.tableHeadCell}
                            >
                                Descuento
                            </TableCell>
                            <TableCell
                                align="center"
                                className={classes.tableHeadCell}
                            >
                                costo
                            </TableCell>
                            <TableCell
                                align="center"
                                className={classes.tableHeadCell}
                            >
                                CLABE interbancaria
                            </TableCell>
                            <TableCell
                                align="center"
                                className={classes.tableHeadCell}
                            >
                                Fecha de archivo
                            </TableCell>
                            <TableCell
                                align="center"
                                className={classes.tableHeadCell}
                            >
                                Status
                            </TableCell>
                            {
                                <TableCell
                                    align="center"
                                    className={classes.tableHeadCell}
                                >
                                    Cargar Archivo
                                </TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {status === LOADING_STATUS.LOADING ||
                        files.length === 0 ? (
                            <TableRow style={{ height: 378 }}>
                                <TableCell
                                    className={classes.noInfo}
                                    colSpan={11}
                                >
                                    {status === LOADING_STATUS.LOADING ? (
                                        <CircularProgress />
                                    ) : (
                                        'Sin información que mostrar'
                                    )}
                                </TableCell>
                            </TableRow>
                        ) : (
                            (rowsPerPage > 0
                                ? files.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                  )
                                : files
                            ).map(row => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        {
                                            paymentTypes?.find(
                                                type =>
                                                    type.id === row.paymentName
                                            )?.text
                                        }
                                    </TableCell>
                                    <TableCell align="center">
                                        {renderDateValue(row.expirationDate)}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.discount}
                                    </TableCell>
                                    <TableCell align="center">
                                        ${row.cost}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.interbankKey}
                                    </TableCell>
                                    <TableCell align="center">
                                        {renderDateValue(row.uploadDate)}
                                    </TableCell>
                                    <TableCell align="center">
                                        {
                                            paymentStatus?.find(
                                                status =>
                                                    status.id ===
                                                    row.paymentStatus
                                            )?.text
                                        }
                                    </TableCell>

                                    {
                                        <TableCell align="center">
                                            <AddNewElement
                                                disabled={
                                                    files[files.indexOf(row)]
                                                        .paymentStatus ===
                                                    'Validado'
                                                }
                                                icon={<DoneAll />}
                                                onAddElement={() => {
                                                    dispatch(
                                                        fetchPaymentInfo(row.id)
                                                    ).then(() => setOpen(true));
                                                }}
                                            >
                                                Validar
                                            </AddNewElement>
                                            <PaymentReview
                                                handleClose={handleClose}
                                                handleFileChange={file => {
                                                    let body = formatInsertPayment(
                                                        {
                                                            ...file,
                                                            user: currentUser,
                                                        }
                                                    );
                                                    dispatch(
                                                        updatePaymentInfo(body)
                                                    )
                                                        .unwrap()
                                                        .then(() => {
                                                            setOpen(false);
                                                        });
                                                }}
                                                open={open}
                                            />
                                        </TableCell>
                                    }
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                    <TableFooter className={classes.tableFooter}>
                        <TableRow>
                            <TablePagination
                                align="left"
                                rowsPerPageOptions={[5, 10, 20, 100]}
                                colSpan={11}
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
};

export default PaymentTable;
