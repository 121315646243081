import axios from 'axios';
import { axiosConfig } from './axiosConfig';

export const getProductList = async () => {
    const { data } = await axios.get('products', axiosConfig('api/v1'));
    return data;
};

export const getProductInfo = async id => {
    const { data } = await axios.get(`products/${id}`, axiosConfig('api/v1'));
    return data;
};

export const createProduct = async body => {
    const { data } = await axios.post('products', body, axiosConfig('api/v1'));
    return data;
};

export const updateProduct = async (id, body) => {
    const { data } = await axios.patch(
        `products/${id}`,
        body,
        axiosConfig('api/v1')
    );
    return data;
};

export const uploadImageProduct = async (id, body) => {
    const { data } = await axios.patch(
        `products/images/${id}`,
        body,
        axiosConfig('api/v1')
    );
    return data;
};

export const getProductLandingList = async () => {
    const { data } = await axios.get('products/landing', axiosConfig('api/v1'));
    return data;
};

export const getLandingProductInfo = async name => {
    const { data } = await axios.get(
        `products/landing/${name}`,
        axiosConfig('api/v1')
    );
    return data;
};
