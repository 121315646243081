export const productMapper = product => {
    return {
        id: product.id,
        image: product.image ?? '',
        name: product.name ?? '',
        categoryId: product.categoryId,
        description: product.description ?? '',
        supplierPrice: product.supplierPrice ?? 0,
        employeePrice: product.employeePrice ?? 0,
        clientPrice: product.clientPrice ?? 0,
        existence: product.existence ?? 0,
    };
};

export const formatData = values => {
    const formatted = {
        name: values.productInfo.name,
        categoryId: values.productInfo.categoryId,
        description: values.productInfo.description
            ? values.productInfo.description
            : null,
        supplierPrice: values.productInfo.supplierPrice,
        employeePrice: values.productInfo.employeePrice,
        clientPrice: values.productInfo.clientPrice,
        existence: values.productInfo.existence,
    };
    return formatted;
};

export const formatInsertImage = values => ({
    filename: values.file,
});
