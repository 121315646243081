import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import bgimage from '../../../../src/assets/images/universidad.jpg';
import cuencoTibetano from '../../../../src/assets/images/cuenco.jpg';
import floresDeBach from '../../../../src/assets/images/flores.jpg';
import hipnoterapia from '../../../../src/assets/images/hipnosis.jpeg';
import acupuntura from '../../../../src/assets/images/acupuntura.jpg';
import logo from '../../../../src/assets/images/holistica_logo.png';
import TopBarHome from '../../../common/TopBarHome/TopBarHome';

//contactDialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { enqueueSnackbar } from '../../pushNotifications/pushNotificationsSlice';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import ContactUs from './contactUs/ContactUs';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        padding: theme.spacing(8, 0, 6),
        placeItems: 'center',
    },
    backgroundImagen: {
        backgroundImage: `url(${bgimage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
        width: 400,
    },
    cardContent: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    root: {
        display: 'flex',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    content: {
        flex: '1 0 auto',
        width: '730px',
    },
    cover: {
        width: 151,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    subtitle: {
        color: 'rgb(0, 123, 124)',
    },
    paper: {
        margin: theme.spacing(5, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    info: {
        textAlign: 'center',
    },
    forDialog: {
        width: '100%',
    },
}));

const cards = [
    {
        name: 'CUENCO TIBETANO',
        description:
            'Son empleados en varios lugares para meditación, inducción al trance, relajación, bienestar personal y práctica religiosa.',
        image: cuencoTibetano,
    },
    {
        name: 'FLORES DE BACH',
        description:
            'Las Flores de Bach son una serie de esencias naturales utilizadas para tratar diversas situaciones emocionales, como miedos, soledad, desesperación, estrés, depresión y obsesiones.',
        image: floresDeBach,
    },
    {
        name: 'HIPNOTERAPIAS Y REGRESIONES',
        description:
            'La hipnosis es un estado de consciencia natural inducido por el Hipnólogo en el que utiliza el poder de la sugestión para que el paciente supere fobias, adiciones o sane enfermedades sicosomáticas o para incrementar destrezas, aptitudes, valores y una serie de recursos que le sirvan para crecer internamente',
        image: hipnoterapia,
    },
    {
        name: 'ACUPUNTURA',
        description:
            'Técnica de medicina tradicional china que trata de la inserción y la manipulación de agujas en el cuerpo con el objetivo de restaurar la salud y el bienestar en el paciente.',
        image: acupuntura,
    },
];

export default function UniversityPage() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [form, setForm] = useState({
        name: '',
        subject: '',
        message: '',
        email: '',
    });
    const handleChange = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className={classes.forDialog}>
                <ContactUs open={open} setOpen={e => setOpen(e)} />
            </div>
            <CssBaseline />
            <main>
                {/* Hero unit */}
                <div className={classes.backgroundImagen}>
                    <div className={classes.heroContent}>
                        <Container maxWidth="sm">
                            <Typography
                                component="h1"
                                variant="h2"
                                align="center"
                                color="textPrimary"
                                gutterBottom
                            >
                                Universidad Holística
                            </Typography>

                            <div className={classes.heroButtons}>
                                <Grid
                                    container
                                    spacing={2}
                                    justifyContent="center"
                                >
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleClickOpen()}
                                        >
                                            Contáctanos
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </Container>
                    </div>
                </div>
                <Container className={classes.cardGrid} maxWidth="lg">
                    {/* End hero unit */}
                    <Grid
                        container
                        spacing={4}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography gutterBottom variant="h4" component="h2">
                            Historia
                        </Typography>
                        <Typography>
                            En 1990 se crea la Escuela Nacional de Medicina
                            Natural con el propósito de formar Terapeutas
                            Holísticos, surge de manera espontánea con un grupo
                            de alumnas y alumnos interesados en el estudio y
                            práctica de los métodos terapéuticos alternativos,
                            lo cual derivó en un Diplomado de Medicina Natural,
                            con un plan de estudios dinámico y estructurado en
                            módulos.
                            <br />
                            <br />
                            La escuela tenía como domicilio Monterrey 241, Col.
                            Roma y contaba con una sala de Yoga, restaurante
                            vegetariano, Aulas para seminarios, y consultorios.
                            <br />
                            <br />
                            En el año de 1993 se integra el Diplomado de Masaje
                            Holístico para capacitar y adiestrar Masoterapeutas
                            en las diferentes técnicas y escuelas del masaje
                            tanto de oriente y de occidente.
                            <br />
                            <br />
                            En 1996 la escuela cambia de domicilio (actual) a la
                            calle de Puebla 402 Col. Roma Norte, Del.
                            Cuauhtémoc.
                            <br />
                            <br />
                            En 1999 en virtud de la demanda de personas de los
                            diferentes estados de la República, interesadas en
                            cursar estos Diplomados, se crea el sistema de
                            Educación a Distancia mediante el Servicio de
                            Mensajería para el envío del material didáctico a
                            los alumnos.
                            <br />
                            <br />
                            En el año 2000 se comenzó a diseñar los Diplomados
                            Virtuales por Internet, los cuales se tiene prevista
                            su implementación a finales del mes de Marzo del
                            2001.
                            <br />
                            <br />
                            En el trascurso de este tiempo la escuela ha
                            preparado alrededor de 350 Terapeutas Holísticos
                            formados dentro del plan de modalidad escolarizada y
                            de 25 alumnos graduados y 30 en proceso de serlo,
                            bajo la modalidad de Educación a Distancia
                            (Correspondencia).
                            <br />
                            <br />
                            En este año 2001 se creo la Universidad Holística,
                            Asociación Civil, que tiene como propósito
                            consolidar académicamente las diferentes escuelas
                            que la conforman.
                            <br />
                            <br />
                            <br />
                        </Typography>
                        <Typography
                            className={classes.subtitle}
                            variant="h5"
                            component="h2"
                        >
                            El Nuevo Enfoque de la Curación
                        </Typography>
                        <Typography>
                            La salud y la armonía humana están basadas en la
                            interdependencia, entre el cuerpo, la mente y el
                            espíritu del individuo, en relación y equilibrio con
                            su medio ambiente. La medicina moderna ha tenido
                            grandes avances, pero le faltará lo esencial, si las
                            personas no aprenden a superar los condicionamientos
                            negativos, a conocer su cuerpo, a buscar su
                            felicidad y deleitarse con la vida.
                            <br />
                            <br />
                            En cuanto a la salud, es más importante la actitud,
                            que la técnica cambias tu percepción y cambias la
                            experiencia de tu cuerpo y de tu mundo; por lo tanto
                            necesitamos reeducarnos en lo que se refiere a la
                            curación.
                            <br />
                            <br />
                            La bioquímica del cuerpo es un producto de la
                            conciencia. Las creencias, pensamientos y emociones,
                            crean las reacciones químicas que sostienen la vida
                            en cada célula. Una célula envejecida es el producto
                            final de una conciencia que ha olvidado cómo
                            mantenerse nueva.
                            <br />
                            <br />
                            Lo importante para la salud es fomentar el
                            equilibrio y la integración del sistema cuerpo,
                            mente y espíritu.
                            <br />
                            <br />
                            Adiestrar a las personas en el manejo de la propia
                            energía y ayudarlas así a conseguir la vitalidad que
                            tanto necesitan. Percibir que en lo más profundo de
                            nosotros, existe un íntimo núcleo, un campo de
                            inmutabilidad que crea tanto a la personalidad, al
                            yo y al cuerpo, y es por tanto al que debemos acudir
                            para lograr la salud. Este SER es nuestro estado
                            esencial, es quien realmente somos.
                            <br />
                            <br />
                            Las medicinas llamadas \"alternativas\" como la
                            Homeopatía, la Acupuntura, la Terapia Floral, así
                            como la Psicología Humanista, son valiosísimos
                            recursos en este nuevo enfoque de la curación, pues
                            son métodos naturales que incrementan nuestra
                            energía y que ayudan al desarrollo de la conciencia
                            corporal y emocional.
                            <br />
                            <br />
                            Como profesionales de la Salud, preocupados por
                            lograr que el individuo encuentre la verdadera
                            curación, nosotros nos hemos dedicado al estudio y
                            práctica de estas especialidades de la medicina. Si
                            a Usted le interesa esta nueva forma de enfocar su
                            curación, nos ponemos a sus órdenes.
                            <br />
                            <br />
                        </Typography>
                    </Grid>
                </Container>
            </main>
            {/* Footer */}
            <footer className={classes.footer}>
                <Typography
                    variant="subtitle1"
                    align="center"
                    color="textSecondary"
                    component="p"
                >
                    Avenida 13 número 166 Colonia Apolo 83100 Hermosillo,
                    Sonora, México
                    <br />
                    Teléfono: 01 662 284 4500
                    <br />
                    http://universidadholistica.com.mx/
                    <br />
                    Lunes a Viernes: 8:00am - 8:00pm Sábado: 8:00am a 2:00pm
                </Typography>
                <Copyright />
            </footer>
            {/* End footer */}
        </>
    );
}
