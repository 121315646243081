import {
    Avatar,
    Button,
    CardMedia,
    Container,
    Grid,
    IconButton,
    MenuItem,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FastField, FieldArray, Form, Formik, getIn } from 'formik';
import { useEffect, useRef, useState } from 'react';
import {
    AddNewElement,
    LabelledCheckbox,
    OutlinedTextField,
} from '../../../common/FormComponents/FormComponents';
import PageHeader from '../../../common/PageHeader/PageHeader';
import DeleteIcon from '@material-ui/icons/Delete';
import { validationSchema } from './mainForm';
import { red } from '@material-ui/core/colors';
import { useParams, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllCatalogs } from '../../catalogs/catalogSlice';
import { formatData } from './utils';
import { LOADING_STATUS } from '../../../helpers/constants';
import { enqueueSnackbar } from '../../pushNotifications/pushNotificationsSlice';
import { renderDateValue } from '../../../helpers/formHelpers';
import CardMediaComponent from '../../../common/cardMediaComponent/cardMediaComponent';
import {
    createSingleProduct,
    fetchProductInfo,
    resetValues,
    updateSingleProduct,
    uploadProductPicture,
} from './SingleProductSlice';

const useStyles = makeStyles(theme => ({
    arraySubtitle: {
        alignItems: 'center',
        display: 'flex',
        fontWeight: 700,
        marginTop: theme.spacing(-1.5),
        marginBottom: theme.spacing(-1.5),
        '$formSubSection + $formSubSection &': {
            marginTop: theme.spacing(2),
        },
        '& > span': {
            marginRight: theme.spacing(1),
        },
    },
    deleteIcon: {
        color: red[400],
        marginTop: theme.spacing(0.2),
    },
    formSection: {
        backgroundColor: theme.palette.grey[50],
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(3),
        '&:not(:last-child)': {
            marginBottom: theme.spacing(4),
        },
    },
    formSectionHeading: {
        color: theme.palette.primary.main,
        marginBottom: 0,
        marginTop: 0,
    },
    formSubSection: {},
    noData: {
        color: theme.palette.text.disabled,
        fontWeight: theme.typography.fontWeightBold,
        letterSpacing: 1,
        textAlign: 'center',
    },
    subtitleWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(4),
    },
    uploadGrid: {
        marginBottom: theme.spacing(1),
    },
    container: {
        display: 'flex',
        margin: theme.spacing(0),
        padding: theme.spacing(0),
        maxWidth: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
    },
    description: {
        whiteSpace: 'pre-wrap',
    },
}));

const SingleProduct = ({ create = false }) => {
    const classes = useStyles();

    const prevSubmitCount = useRef(0);

    const [edit, setEdit] = useState(create);
    const [profileImage, setProfileImage] = useState('');

    const { id } = useParams();
    const history = useHistory();

    const dispatch = useDispatch();
    const product = useSelector(state => state.singleProduct.product);
    // const catalogs = useSelector(state => state.catalogs.catalogs);
    const insertStatus = useSelector(state => state.singleProduct.insertStatus);
    const fetchStatus = useSelector(
        state => state.singleProduct.fetchProductInfoStatus
    );
    const catalogs = useSelector(state => state.catalogs.catalogs);
    const catalogStatus = useSelector(state => state.catalogs.catalogs.status);

    const loading =
        fetchStatus === LOADING_STATUS.LOADING ||
        catalogStatus === LOADING_STATUS.LOADING;

    const insertingProduct = insertStatus === LOADING_STATUS.LOADING;

    useEffect(() => {
        if (id) {
            dispatch(fetchProductInfo(id)).then(({ payload }) =>
                setProfileImage(payload.image)
            );
        }
    }, [dispatch, id]);

    useEffect(() => {
        dispatch(fetchAllCatalogs());
    }, [dispatch]);

    // useEffect(() => {
    //     if (fetchStatus === LOADING_STATUS.SUCCEEDED) {
    //         setFiles(user.documents);
    //     }
    // }, [user.documents, fetchStatus]);

    useEffect(() => {
        if (insertStatus === LOADING_STATUS.SUCCEEDED) {
            history.push('/products');
            dispatch(resetValues());
        }
    }, [dispatch, history, insertStatus]);

    const handleButtonClick = e => {
        e.preventDefault();
        if (!edit) {
            setEdit(true);
        }
    };

    //form data init

    // function buildFormData(formData, data, parentKey) {
    //     if (data && typeof data === 'object') {
    //         Object.keys(data).forEach(key => {
    //             buildFormData(
    //                 formData,
    //                 data[key],
    //                 parentKey ? `${parentKey}[${key}]` : key
    //             );
    //         });
    //     } else {
    //         const value = data == null ? '' : data;

    //         formData.append(parentKey, value);
    //     }
    // }

    // function jsonToFormData(data) {
    //     const formData = new FormData();

    //     buildFormData(formData, data);

    //     return formData;
    // }

    // function imageToFormData(data) {
    //     const formData = new FormData();
    //     buildFormData(formData, data);
    //     formData.append('Image', data);
    //     return formData;
    // }

    // const handleFileChange = file => {
    //     const imageFile = formatInsertImage(file);
    //     let body = jsonToFormData(objectFile);
    //     dispatch(uploadPayment(body))
    //         .unwrap()
    //         .then(() => {
    //             handleClose();
    //         });
    // };

    // form data end

    const handleSubmit = values => {
        if (id) {
            if (profileImage !== product.productInfo.image) {
                const formData = new FormData();
                formData.append(
                    'image',
                    new File([profileImage], `product_${id}.jpg`)
                );
                dispatch(
                    uploadProductPicture({
                        id,
                        body: formData,
                    })
                );
            }
            if (
                values.productInfo.name !== product.productInfo.name ||
                values.productInfo.description !==
                    product.productInfo.description ||
                values.productInfo.existence !==
                    product.productInfo.existence ||
                values.productInfo.clientPrice !==
                    product.productInfo.clientPrice ||
                values.productInfo.supplierPrice !==
                    product.productInfo.supplierPrice ||
                values.productInfo.employeePrice !==
                    product.productInfo.employeePrice
            ) {
                let formattedUser = formatData(values);
                dispatch(
                    updateSingleProduct({
                        id: id,
                        body: formattedUser,
                    })
                );
            } else {
                setEdit(false);
            }
        } else {
            let body = {
                ...formatData(values),
            };
            dispatch(createSingleProduct(body)).then(e => {
                const formData = new FormData();
                formData.append(
                    'image',
                    new File([profileImage], `product_${e.payload.id}.jpg`)
                );
                dispatch(
                    uploadProductPicture({
                        id: e.payload.id,
                        body: formData,
                    })
                );
            });
        }
    };

    const handleFieldUpdate = (nextProps, currentProps) => {
        if (
            nextProps.edit !== currentProps.edit ||
            nextProps.disabled !== currentProps.disabled ||
            nextProps.loading !== currentProps.loading ||
            nextProps.catalog !== currentProps.catalog ||
            nextProps.name !== currentProps.name ||
            getIn(nextProps.formik.values, currentProps.name) !==
                getIn(currentProps.formik.values, currentProps.name) ||
            getIn(nextProps.formik.errors, currentProps.name) !==
                getIn(currentProps.formik.errors, currentProps.name) ||
            getIn(nextProps.formik.touched, currentProps.name) !==
                getIn(currentProps.formik.touched, currentProps.name) ||
            Object.keys(currentProps).length !==
                Object.keys(nextProps).length ||
            nextProps.formik.isSubmitting !== currentProps.formik.isSubmitting
        ) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <main>
            <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={product}
                onSubmit={handleSubmit}
            >
                {props => {
                    const { errors, submitCount, values } = props;
                    if (submitCount !== prevSubmitCount.current) {
                        prevSubmitCount.current = submitCount;
                        if (Object.keys(errors).length > 0) {
                            dispatch(
                                enqueueSnackbar({
                                    message:
                                        'Por favor llene todos los campos correctamente.',
                                    options: {
                                        key:
                                            new Date().getTime() +
                                            Math.random(),
                                        variant: 'error',
                                    },
                                })
                            );
                        }
                    }
                    return (
                        <Form>
                            <PageHeader
                                title={
                                    create
                                        ? 'Nuevo producto'
                                        : edit
                                        ? 'Editar producto'
                                        : 'Consulta de producto'
                                }
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type={edit ? 'submit' : 'button'}
                                    onClick={
                                        !edit ? handleButtonClick : undefined
                                    }
                                    disabled={loading || insertingProduct}
                                >
                                    {create
                                        ? 'Crear'
                                        : edit
                                        ? 'Aplicar cambios'
                                        : 'Editar'}
                                </Button>
                            </PageHeader>
                            <section className={classes.formSection}>
                                <div className={classes.subtitleWrapper}>
                                    <h2 className={classes.formSectionHeading}>
                                        Información General
                                    </h2>
                                </div>
                                <Container className={classes.container}>
                                    <CardMediaComponent
                                        edit={edit}
                                        handleFileChange={e => {
                                            setProfileImage(e);
                                        }}
                                        onClick={e => null}
                                        title={values.productInfo.name}
                                        loading={loading}
                                        image={
                                            profileImage === null
                                                ? ''
                                                : profileImage
                                        }
                                        alt={'imagen de producto'}
                                    />
                                    <Grid container spacing={3}>
                                        <FastField
                                            component={OutlinedTextField}
                                            name="productInfo.name"
                                            type="text"
                                            label="Nombre*"
                                            edit={edit}
                                            loading={loading}
                                            disabled={insertingProduct}
                                            shouldUpdate={handleFieldUpdate}
                                        />
                                        <FastField
                                            component={OutlinedTextField}
                                            select
                                            name="productInfo.categoryId"
                                            type="text"
                                            label="Categoría*"
                                            edit={edit}
                                            loading={loading}
                                            disabled={insertingProduct}
                                            shouldUpdate={handleFieldUpdate}
                                            catalog={catalogs.categories}
                                        >
                                            {catalogs.categories?.length > 0 &&
                                                catalogs.categories.map(
                                                    ({ id, text }) => (
                                                        <MenuItem
                                                            value={id}
                                                            key={id}
                                                        >
                                                            {text}
                                                        </MenuItem>
                                                    )
                                                )}
                                        </FastField>
                                        <FastField
                                            component={OutlinedTextField}
                                            name="productInfo.existence"
                                            type="number"
                                            label="Existencia*"
                                            edit={edit}
                                            loading={loading}
                                            disabled={insertingProduct}
                                            shouldUpdate={handleFieldUpdate}
                                        />
                                        <FastField
                                            component={OutlinedTextField}
                                            name="productInfo.clientPrice"
                                            type="number"
                                            label="Precio cliente*"
                                            edit={edit}
                                            loading={loading}
                                            disabled={insertingProduct}
                                            shouldUpdate={handleFieldUpdate}
                                        />
                                        <FastField
                                            component={OutlinedTextField}
                                            name="productInfo.supplierPrice"
                                            type="number"
                                            label="Precio proveedor*"
                                            edit={edit}
                                            loading={loading}
                                            disabled={insertingProduct}
                                            shouldUpdate={handleFieldUpdate}
                                        />
                                        <FastField
                                            component={OutlinedTextField}
                                            name="productInfo.employeePrice"
                                            type="number"
                                            label="Precio empleado*"
                                            edit={edit}
                                            loading={loading}
                                            disabled={insertingProduct}
                                            shouldUpdate={handleFieldUpdate}
                                        />
                                        <FastField
                                            className={classes.description}
                                            component={OutlinedTextField}
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            multiline
                                            maxRows={4}
                                            name="productInfo.description"
                                            type="text"
                                            label="Descripción"
                                            edit={edit}
                                            loading={loading}
                                            disabled={insertingProduct}
                                            shouldUpdate={handleFieldUpdate}
                                        />
                                    </Grid>
                                </Container>
                            </section>
                        </Form>
                    );
                }}
            </Formik>
        </main>
    );
};

export default SingleProduct;
