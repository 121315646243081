import * as yup from 'yup';
import {
    maxCharMessage,
    minCharMessage,
    requiredMessage,
    onlyLetters,
    onlyNumbers,
    onlyLettersMessage,
} from '../../../helpers/validationSchemaHelpers';

export const initialValues = {
    productInfo: {
        name: '',
        image: '',
        description: '',
        categoryId: '',
        supplierPrice: 0,
        clientPrice: 0,
        employeePrice: 0,
        existence: 0,
    },
};

export const validationSchema = yup.object({
    productInfo: yup.object({
        name: yup
            .string()
            .matches(onlyLetters, onlyLettersMessage)
            .max(50, maxCharMessage),
        description: yup
            .string()
            .min(50, minCharMessage)
            .max(500, maxCharMessage),
        categoryId: yup.number().required(requiredMessage),
        clientPrice: yup
            .string()
            .required(requiredMessage)
            .matches(onlyNumbers),
        supplierPrice: yup
            .string()
            .required(requiredMessage)
            .matches(onlyNumbers),
        existence: yup.string().required(requiredMessage).matches(onlyNumbers),
    }),
});
