import axios from 'axios';
import { Redirect, Route } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllUserData, fetchPermissionsOfUser } from './authSlice';
import { LOADING_STATUS } from '../helpers/constants';
import Loading from '../common/Loading/Loading';
import Error from '../common/Error/Error';
import Register from '../features/register/Register';
import Cookies from 'js-cookie';

const PrivateRoute = ({ children, ...rest }) => {
    const dataState = useSelector(state => state.user.statusData);
    // const permissionsState = useSelector(state => state.user.statusPermissions);
    const allUserData = useSelector(state => state.user.userData);
    // const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const isAuthenticated = rest.isAuthenticated;
    const [obtainedToken, setObtainedToken] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        const token = Cookies.get('token');
        if (token) {
            axios.interceptors.request.use(
                config => {
                    config.headers.authorization = `Bearer ${token}`;
                    return config;
                },
                error => {
                    return Promise.reject(error);
                }
            );
        }
    }, []);

    useEffect(() => {
        dispatch(fetchAllUserData());
    }, [dispatch]);

    return isAuthenticated ? (
        dataState === LOADING_STATUS.SUCCEEDED ? (
            <Route {...rest} render={() => children} />
        ) : dataState === LOADING_STATUS.LOADING ||
          dataState === LOADING_STATUS.IDLE ? (
            <Loading />
        ) : (
            <Error />
        )
    ) : (
        <Route
            {...rest}
            render={({ location }) => (
                <>
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                </>
            )}
        />
    );
};

export default PrivateRoute;
