import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { changeOption } from '../schedulerSlice';
import Button from '@mui/material/Button';
import { styled, alpha } from '@mui/material/styles';
import ButtonGroup from '@mui/material/ButtonGroup';
import {
    Scheduler,
    WeekView,
    Toolbar,
    DateNavigator,
    Appointments,
    DayView,
    ViewSwitcher,
    Resources,
    AppointmentTooltip,
    EditRecurrenceMenu,
    AppointmentForm,
} from '@devexpress/dx-react-scheduler-material-ui';
import classNames from 'clsx';

const useStyles = makeStyles(theme => ({
    filtersContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(3),
        '& .MuiTextField-root': {
            minWidth: '25ch',
            maxWidth: `calc(25% - (3px * ${theme.spacing(2)} / 4))`,
            flexGrow: 1,
            '&:not(:last-child)': {
                marginRight: theme.spacing(2),
                marginBottom: theme.spacing(1),
            },
        },
    },
}));

const PREFIX = 'Demo';

const classes = {
    flexibleSpace: `${PREFIX}-flexibleSpace`,
    textField: `${PREFIX}-textField`,
    locationSelector: `${PREFIX}-locationSelector`,
    button: `${PREFIX}-button`,
    selectedButton: `${PREFIX}-selectedButton`,
    longButtonText: `${PREFIX}-longButtonText`,
    shortButtonText: `${PREFIX}-shortButtonText`,
    title: `${PREFIX}-title`,
    textContainer: `${PREFIX}-textContainer`,
    time: `${PREFIX}-time`,
    text: `${PREFIX}-text`,
    container: `${PREFIX}-container`,
    weekendCell: `${PREFIX}-weekendCell`,
    weekEnd: `${PREFIX}-weekEnd`,
};

// #FOLD_BLOCK
const StyledTextField = styled(TextField)(({ theme: { spacing } }) => ({
    [`&.${classes.textField}`]: {
        width: '100px',
        marginLeft: spacing(1),
        marginTop: 0,
        marginBottom: 0,
        height: spacing(4.875),
    },
}));
// #FOLD_BLOCK
const StyledButtonGroup = styled(ButtonGroup)(
    ({ theme: { spacing, palette } }) => ({
        [`&.${classes.locationSelector}`]: {
            marginLeft: spacing(1),
            height: spacing(4.875),
        },
        [`& .${classes.longButtonText}`]: {
            '@media (max-width: 800px)': {
                display: 'none',
            },
        },
        [`& .${classes.shortButtonText}`]: {
            '@media (min-width: 800px)': {
                display: 'none',
            },
        },
        [`& .${classes.button}`]: {
            paddingLeft: spacing(1),
            paddingRight: spacing(1),
            width: spacing(10),
            '@media (max-width: 800px)': {
                width: spacing(2),
                fontSize: '0.75rem',
            },
        },
        [`& .${classes.selectedButton}`]: {
            backgroundColor: '#036798',
            color: 'rgb(255, 255, 255)',
            paddingLeft: spacing(1),
            paddingRight: spacing(1),
            width: spacing(10),
            '&:hover': {
                backgroundColor: '#023f5d',
            },
            border: `1px solid ${palette.primary[400]}!important`,
            borderLeft: `1px solid ${palette.primary[50]}!important`,
            '&:first-of-type': {
                borderLeft: `1px solid ${palette.primary[50]}!important`,
            },
        },
    })
);
// #FOLD_BLOCK
const StyledToolbarFlexibleSpace = styled(Toolbar.FlexibleSpace)(() => ({
    [`&.${classes.flexibleSpace}`]: {
        margin: '0 auto 0 0',
        display: 'flex',
        alignItems: 'center',
    },
}));

const getButtonClass = (locations, location) => {
    if (locations.indexOf(location) > -1) {
        return classes.selectedButton;
    }
    return classes.button;
};

const SchedulerFilters = ({ props, locationFilter = { options: [] } }) => {
    const dispatch = useDispatch();

    const handleChange = ({ target: { name, value } }) => {
        dispatch(changeOption({ name, value }));
    };

    const handleButtonClick = (locationName, locations) => {
        let name = 'locationFilter';
        if (locations.indexOf(locationName) > -1) {
            let value = locations.filter(location => location !== locationName);
            return dispatch(changeOption({ name, value }));
        }
        let value = [...locations];
        value.push(locationName);

        return dispatch(changeOption({ name, value }));
    };

    return (
        <>
            {/* 
            <SelectField
                label="Tipo"
                name="subjectTypeOptions"
                onChange={handleChange}
                options={sort(subjectTypeOptions)}
            />
            <SelectField
                label="Status"
                name="subjectStatusOptions"
                onChange={handleChange}
                options={sort(subjectStatusOptions)}
            /> */}
            <Toolbar
                flexibleSpaceComponent={() => (
                    <StyledToolbarFlexibleSpace
                        {...props}
                        className={classes.flexibleSpace}
                    >
                        {/* <StyledTextField
                            size="small"
                            placeholder="Filter"
                            className={classes.textField}
                            variant="outlined"
                            hiddenLabel
                            margin="dense"
                            name="generalFilter"
                            onChange={handleChange}
                        /> */}
                        <StyledButtonGroup className={classes.locationSelector}>
                            {locationFilter.options.map((location, index) => (
                                <Button
                                    className={getButtonClass(
                                        locationFilter.selected,
                                        location
                                    )}
                                    onClick={() =>
                                        handleButtonClick(
                                            location,
                                            locationFilter.selected
                                        )
                                    }
                                    key={location}
                                >
                                    <>
                                        <span
                                            className={classes.shortButtonText}
                                        >
                                            {[index]}
                                        </span>
                                        <span
                                            className={classes.longButtonText}
                                        >
                                            {location}
                                        </span>
                                    </>
                                </Button>
                            ))}
                        </StyledButtonGroup>
                    </StyledToolbarFlexibleSpace>
                )}
            />
        </>
    );
};

export default SchedulerFilters;
