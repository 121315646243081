import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
    IconButton,
    MenuItem,
    Grid,
    Button,
    Container,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { FastField, Form, Formik, getIn, FieldArray } from 'formik';
import {
    AddNewContact,
    AddNewElement,
    OutlinedTextField,
    LabelledCheckbox,
} from '../../../../common/FormComponents/FormComponents';
import { validationPetsSchema, validationSchema } from '../mainForm';
import { enqueueSnackbar } from '../../../pushNotifications/pushNotificationsSlice';
import { renderDateValue } from '../../../../helpers/formHelpers';
import { fetchCourseModalities } from '../../../catalogs/catalogSlice';
import EditIcon from '@material-ui/icons/Edit';
import { initialPetInfo } from '../mainForm';
// import { formatInsertCourses, formatInsertSchedule } from '../utils';
// import {
//     courseAssignment,
//     createCourseSchedule,
//     createSubjectCourse,
//     fetchSingleSubject,
//     newStudent,
//     updateSubjectCourse,
// } from '../singleSubjectSlice';
import axios from 'axios';
import { LOADING_STATUS } from '../../../../helpers/constants';
import { useHistory, useParams } from 'react-router-dom';
import {
    formatCreatePet,
    formatUpdateEthogram,
    formatUpdatePet,
} from '../utils';
import {
    createSinglePet,
    updateSinglePet,
    uploadPetPicture,
} from '../singleClientSlice';
import CardMediaComponent from '../../../../common/cardMediaComponent/cardMediaComponent';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    forMain: {
        width: '100%',
    },
    forEdit: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(1, 0, 0, 0),
    },
    scheduleTable: {
        padding: theme.spacing(3, 0),
    },
    deleteIcon: {
        color: 'rgb(255, 0, 0)',
        marginTop: theme.spacing(0.2),
    },
    subtitleWrapper: {
        backgroundColor: theme.palette.grey[50],
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: theme.spacing(4),
    },
    formSectionHeading: {
        color: theme.palette.primary.main,
        marginBottom: 0,
        marginTop: 0,
    },
    cancelButton: {
        margin: theme.spacing(0, 2),
    },
    noData: {
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.text.disabled,
        fontWeight: theme.typography.fontWeightBold,
        letterSpacing: 1,
        textAlign: 'center',
    },
    container: {
        display: 'flex',
        margin: theme.spacing(0),
        padding: theme.spacing(0),
        maxWidth: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
    },
}));

export default function PetsTabs({
    petId,
    role,
    pets = [],
    catalogs,
    loading,
    insertingClient,
}) {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [options, setOptions] = useState([]);
    const [schedule, setSchedule] = useState([]);
    const prevSubmitCount = useRef(0);
    const [edit, setEdit] = useState(false);
    const dispatch = useDispatch();
    const { id } = useParams();
    const [profileImage, setProfileImage] = useState('');

    const history = useHistory();

    const dropdownTeachers = useSelector(
        state => state.dropdown.dropdownTeachers
    );
    const userRole = useSelector(state => state.user.userData.role);
    const idUser = useSelector(state => state.user.userData.id);

    const studentInfo = useSelector(state => state.user.userData.studentInfo);

    const assignmentStatus = useSelector(
        state => state.singleSubject.courseAssignment
    );

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (pets.length > options.length) {
            setOptions([...pets]);
        }
    }, [options, setOptions, pets]);

    useEffect(() => {
        // if (assignmentStatus === LOADING_STATUS.SUCCEEDED) {
        //     dispatch(fetchSingleSubject());
        // }
    }, [dispatch, assignmentStatus]);

    // useEffect(() => {
    //     options.map((option, index) => {
    //         if (option.schedule.length === 0) {
    //             let newOption = {
    //                 ...option,
    //                 schedule: [...initialScheduleInfo],
    //             };
    //             let newArrayOptions = [...options];
    //             newArrayOptions[index] = { ...newOption };
    //             setOptions([...newArrayOptions]);
    //         }
    //     });
    // }, [options]);

    // const optionDeleted = (option, index) => {
    //     let newOptions = [...options];
    //     newOptions[index] = { ...option, shouldDelete: true };
    //     onDelete(newOptions);
    // };

    const handleButtonClick = e => {
        e.preventDefault();
        if (!edit) {
            setEdit(true);
        }
    };

    const handleSubmit = values => {
        // let newValues = formatInsertCourses(values, Number(petId));
        // if (values.idCourse) {
        //     let newBody = formatInsertSchedule(values, Number(values.idCourse));
        //     dispatch(updateSubjectCourse(newValues))
        //         .unwrap()
        //         .then(() =>
        //             dispatch(createCourseSchedule(newBody))
        //                 .unwrap()
        //                 .then(() => {
        //                     dispatch(fetchSingleSubject(id));
        //                     setEdit(false);
        //                 })
        //         );
        // } else {
        //     dispatch(createSubjectCourse(newValues))
        //         .unwrap()
        //         .then(result => {
        //             let newBody = formatInsertSchedule(
        //                 values,
        //                 Number(result.course.idCourse)
        //             );
        //             dispatch(createCourseSchedule(newBody))
        //                 .unwrap()
        //                 .then(() => {
        //                     dispatch(fetchSingleSubject(id));
        //                     setEdit(false);
        //                 })
        //                 .catch(() => {
        //                     dispatch(fetchSingleSubject(id));
        //                     setEdit(false);
        //                 });
        //         });
        // }

        if (values.petId) {
            let petId = values.petId;
            let ethogramId = values.ethogram.ethogramId;
            let body = {
                pet: {
                    ...formatUpdatePet(values),
                },
                ethogram: { ...formatUpdateEthogram(values) },
            };
            dispatch(updateSinglePet({ petId, ethogramId, body }));
            if (typeof values.image === 'object') {
                const formData = new FormData();
                formData.append(
                    'image',
                    new File([values.image], `pet_${values.petId}.jpg`)
                );
                dispatch(
                    uploadPetPicture({
                        id: petId,
                        body: formData,
                    })
                );
            }
            //     if (value.id) {
            //         if (value.trainerId !== client.trainers[index].trainerId) {
            //             await dispatch(
            //                 updateSingleTrainerAssign({ id: value.id, body })
            //             );
            //         }
            //     } else {
            //         await dispatch(createSingleTrainerAssign(body));
            //     }
            // // dispatch(updateSingleClient({ id, body }));
        } else {
            let body = formatCreatePet(values);
            dispatch(createSinglePet(body)).then(e => {
                const formData = new FormData();
                formData.append(
                    'image',
                    new File([values.image], `pet_${e.payload.id}.jpg`)
                );
                dispatch(
                    uploadPetPicture({
                        id: e.payload.id,
                        body: formData,
                    })
                );
            });
            // dispatch(createSingleClient(body));
        }
    };

    const handleAssign = course => {
        // if (studentInfo) {
        //     let body = {
        //         idStudent: studentInfo.idStudent,
        //         idCourse: course,
        //         status: 1,
        //     };
        //     dispatch(courseAssignment(body))
        //         .unwrap()
        //         .then(() => history.push(`/subjects`));
        // } else {
        //     let studentBody = {
        //         idUser: idUser,
        //         registrationNumber: '0',
        //         status: 1,
        //     };
        //     dispatch(newStudent(studentBody))
        //         .unwrap()
        //         .then(result => {
        //             let body = {
        //                 idStudent: result.idStudent,
        //                 idCourse: course,
        //                 status: 1,
        //             };
        //             dispatch(courseAssignment(body))
        //                 .unwrap()
        //                 .then(() => window.location.reload());
        //         });
        // }
    };

    const handleFieldUpdate = (nextProps, currentProps) => {
        if (
            nextProps.edit !== currentProps.edit ||
            nextProps.disabled !== currentProps.disabled ||
            nextProps.catalog !== currentProps.catalog ||
            nextProps.loading !== currentProps.loading ||
            nextProps.name !== currentProps.name ||
            getIn(nextProps.formik.values, currentProps.name) !==
                getIn(currentProps.formik.values, currentProps.name) ||
            getIn(nextProps.formik.errors, currentProps.name) !==
                getIn(currentProps.formik.errors, currentProps.name) ||
            getIn(nextProps.formik.touched, currentProps.name) !==
                getIn(currentProps.formik.touched, currentProps.name) ||
            Object.keys(currentProps).length !==
                Object.keys(nextProps).length ||
            nextProps.formik.isSubmitting !== currentProps.formik.isSubmitting
        ) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.subtitleWrapper}>
                <h2 className={classes.formSectionHeading}>Mascotas</h2>
                <Grid container spacing={3} justifyContent="flex-end">
                    {role === 'Entrenador' && !edit && (
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => setEdit(true)}
                            disabled={loading}
                        >
                            Editar
                        </Button>
                    )}
                    {edit && (
                        <Grid>
                            <Button
                                color="secondary"
                                variant="contained"
                                className={classes.cancelButton}
                                onClick={async () => {
                                    await setOptions([...pets]);
                                    await setValue(0);
                                    await setEdit(false);
                                }}
                                disabled={loading}
                            >
                                Cancelar
                            </Button>
                            <AddNewElement
                                disabled={loading || insertingClient}
                                onAddElement={() => {
                                    let petsArray = [...options];

                                    petsArray.push({
                                        ...initialPetInfo,
                                        clientId: Number(id),
                                    });
                                    setOptions([...petsArray]);
                                }}
                            />
                        </Grid>
                    )}
                </Grid>
            </div>
            {options.length > 0 ? (
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        {options.map((option, index) => (
                            <Tab
                                label={
                                    option.name.length > 0
                                        ? option.name
                                        : `Mascota ${index + 1}`
                                }
                                {...a11yProps(options.indexOf(option))}
                            />
                        ))}
                    </Tabs>
                </AppBar>
            ) : (
                <Typography className={classes.noData}>
                    Aún no hay mascotas.
                </Typography>
            )}

            {options.map((option, index) => (
                <TabPanel value={value} index={options.indexOf(option)}>
                    <Grid key={index} container spacing={3}>
                        <main className={classes.forMain}>
                            <Formik
                                enableReinitialize
                                validationSchema={validationPetsSchema}
                                initialValues={option}
                                onSubmit={handleSubmit}
                            >
                                {props => {
                                    const {
                                        errors,
                                        submitCount,
                                        values,
                                        setValues,
                                    } = props;
                                    if (
                                        submitCount !== prevSubmitCount.current
                                    ) {
                                        prevSubmitCount.current = submitCount;
                                        if (Object.keys(errors).length > 0) {
                                            dispatch(
                                                enqueueSnackbar({
                                                    message:
                                                        'Por favor llene todos los campos correctamente.',
                                                    options: {
                                                        key:
                                                            new Date().getTime() +
                                                            Math.random(),
                                                        variant: 'error',
                                                    },
                                                })
                                            );
                                        }
                                    }
                                    return (
                                        <Form>
                                            <section
                                                className={classes.formSection}
                                            >
                                                <Container
                                                    className={
                                                        classes.container
                                                    }
                                                >
                                                    <CardMediaComponent
                                                        edit={edit}
                                                        handleFileChange={async e => {
                                                            let newOptions = [
                                                                ...options,
                                                            ];
                                                            newOptions[
                                                                index
                                                            ] = {
                                                                ...values,
                                                                image: e,
                                                            };
                                                            setOptions(
                                                                newOptions
                                                            );
                                                        }}
                                                        onClick={e => null}
                                                        title={values.name}
                                                        loading={loading}
                                                        image={values.image}
                                                        alt={
                                                            'imagen de mascota'
                                                        }
                                                    />
                                                    <Grid container spacing={3}>
                                                        <FastField
                                                            component={
                                                                OutlinedTextField
                                                            }
                                                            name={`name`}
                                                            type="text"
                                                            label="Nombre*"
                                                            edit={edit}
                                                            loading={loading}
                                                            disabled={
                                                                insertingClient
                                                            }
                                                            shouldUpdate={
                                                                handleFieldUpdate
                                                            }
                                                        />
                                                        <FastField
                                                            component={
                                                                OutlinedTextField
                                                            }
                                                            name={`breed`}
                                                            type="text"
                                                            label="Raza"
                                                            edit={edit}
                                                            loading={loading}
                                                            disabled={
                                                                insertingClient
                                                            }
                                                            shouldUpdate={
                                                                handleFieldUpdate
                                                            }
                                                        />
                                                        <FastField
                                                            component={
                                                                OutlinedTextField
                                                            }
                                                            select
                                                            name={`genderId`}
                                                            type="select"
                                                            label="sexo*"
                                                            catalog={
                                                                catalogs.genders
                                                            }
                                                            edit={edit}
                                                            loading={loading}
                                                            disabled={
                                                                insertingClient
                                                            }
                                                            shouldUpdate={
                                                                handleFieldUpdate
                                                            }
                                                        >
                                                            <MenuItem value="">
                                                                &nbsp;
                                                            </MenuItem>
                                                            {catalogs.genders
                                                                ?.length > 0 &&
                                                                catalogs.genders.map(
                                                                    ({
                                                                        id,
                                                                        text,
                                                                    }) => (
                                                                        <MenuItem
                                                                            value={
                                                                                id
                                                                            }
                                                                            key={
                                                                                id
                                                                            }
                                                                        >
                                                                            {
                                                                                text
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                )}
                                                        </FastField>
                                                        <FastField
                                                            component={
                                                                OutlinedTextField
                                                            }
                                                            select
                                                            name={`ethogram.adoptionId`}
                                                            type="select"
                                                            label="tipo de adopción*"
                                                            catalog={
                                                                catalogs.adoptions
                                                            }
                                                            edit={edit}
                                                            loading={loading}
                                                            disabled={
                                                                insertingClient
                                                            }
                                                            shouldUpdate={
                                                                handleFieldUpdate
                                                            }
                                                        >
                                                            <MenuItem value="">
                                                                &nbsp;
                                                            </MenuItem>
                                                            {catalogs.genders
                                                                ?.length > 0 &&
                                                                catalogs.adoptions.map(
                                                                    ({
                                                                        id,
                                                                        text,
                                                                    }) => (
                                                                        <MenuItem
                                                                            value={
                                                                                id
                                                                            }
                                                                            key={
                                                                                id
                                                                            }
                                                                        >
                                                                            {
                                                                                text
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                )}
                                                        </FastField>
                                                        <FastField
                                                            component={
                                                                OutlinedTextField
                                                            }
                                                            name={`ethogram.birthday`}
                                                            type="date"
                                                            label="Fecha de nacimiento*"
                                                            edit={edit}
                                                            renderValue={
                                                                renderDateValue
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            loading={loading}
                                                            disabled={
                                                                insertingClient
                                                            }
                                                            shouldUpdate={
                                                                handleFieldUpdate
                                                            }
                                                        />
                                                        <FastField
                                                            component={
                                                                OutlinedTextField
                                                            }
                                                            name={`ethogram.adoptionDate`}
                                                            type="date"
                                                            label="Fecha de adopción"
                                                            edit={edit}
                                                            renderValue={
                                                                renderDateValue
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            loading={loading}
                                                            disabled={
                                                                insertingClient
                                                            }
                                                            shouldUpdate={
                                                                handleFieldUpdate
                                                            }
                                                        />
                                                        <FastField
                                                            component={
                                                                OutlinedTextField
                                                            }
                                                            name={`ethogram.living`}
                                                            type="text"
                                                            label="¿Donde vive?"
                                                            edit={edit}
                                                            loading={loading}
                                                            disabled={
                                                                insertingClient
                                                            }
                                                            shouldUpdate={
                                                                handleFieldUpdate
                                                            }
                                                        />
                                                        <FastField
                                                            component={
                                                                OutlinedTextField
                                                            }
                                                            name={`ethogram.socialization`}
                                                            type="text"
                                                            label="Socialización"
                                                            edit={edit}
                                                            loading={loading}
                                                            disabled={
                                                                insertingClient
                                                            }
                                                            shouldUpdate={
                                                                handleFieldUpdate
                                                            }
                                                        />
                                                        <FastField
                                                            component={
                                                                OutlinedTextField
                                                            }
                                                            name={`ethogram.weeklyWalks`}
                                                            type="number"
                                                            label="Paseos a la semana"
                                                            edit={edit}
                                                            loading={loading}
                                                            disabled={
                                                                insertingClient
                                                            }
                                                            shouldUpdate={
                                                                handleFieldUpdate
                                                            }
                                                        />
                                                        <FastField
                                                            component={
                                                                OutlinedTextField
                                                            }
                                                            name={`ethogram.feeding`}
                                                            type="text"
                                                            label="Alimentación"
                                                            edit={edit}
                                                            loading={loading}
                                                            disabled={
                                                                insertingClient
                                                            }
                                                            shouldUpdate={
                                                                handleFieldUpdate
                                                            }
                                                        />
                                                        <FastField
                                                            component={
                                                                OutlinedTextField
                                                            }
                                                            name={`ethogram.diseases`}
                                                            type="text"
                                                            label="Enfermedades o alergias"
                                                            edit={edit}
                                                            loading={loading}
                                                            disabled={
                                                                insertingClient
                                                            }
                                                            shouldUpdate={
                                                                handleFieldUpdate
                                                            }
                                                        />
                                                        <FastField
                                                            component={
                                                                LabelledCheckbox
                                                            }
                                                            type="checkbox"
                                                            name={`ethogram.vaccinationComplete`}
                                                            label="cuadro de vacunación completo*"
                                                            edit={edit}
                                                            loading={loading}
                                                            disabled={
                                                                insertingClient
                                                            }
                                                            shouldUpdate={
                                                                handleFieldUpdate
                                                            }
                                                        />
                                                        {values.ethogram
                                                            .vaccinationComplete && (
                                                            <FastField
                                                                component={
                                                                    OutlinedTextField
                                                                }
                                                                name={`ethogram.sextupleVaccine`}
                                                                type="date"
                                                                label="vacuna sextuple*"
                                                                edit={edit}
                                                                renderValue={
                                                                    renderDateValue
                                                                }
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                loading={
                                                                    loading
                                                                }
                                                                disabled={
                                                                    insertingClient
                                                                }
                                                                shouldUpdate={
                                                                    handleFieldUpdate
                                                                }
                                                            />
                                                        )}
                                                        <FastField
                                                            component={
                                                                OutlinedTextField
                                                            }
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            multiline
                                                            maxRows={4}
                                                            name={`ethogram.targets`}
                                                            type="text"
                                                            label="Objetivos"
                                                            edit={edit}
                                                            loading={loading}
                                                            disabled={
                                                                insertingClient
                                                            }
                                                            shouldUpdate={
                                                                handleFieldUpdate
                                                            }
                                                        />
                                                    </Grid>
                                                </Container>

                                                <div
                                                    className={classes.forEdit}
                                                >
                                                    {edit && (
                                                        <Button
                                                            color="primary"
                                                            variant="contained"
                                                            type="submit"
                                                            disabled={
                                                                loading ||
                                                                values ===
                                                                    pets[index]
                                                            }
                                                        >
                                                            Aplicar cambios
                                                        </Button>
                                                    )}
                                                </div>
                                            </section>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </main>
                    </Grid>
                </TabPanel>
            ))}
        </div>
    );
}
