import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    getProductInfo,
    createProduct,
    updateProduct,
    uploadImageProduct,
    getLandingProductInfo,
} from '../../../api/products';
import { getRolesList } from '../../../api/roles';
import { LOADING_STATUS } from '../../../helpers/constants';
import { initialValues } from './mainForm';
import { productMapper } from './utils';
import { enqueueSnackbar } from '../../pushNotifications/pushNotificationsSlice';

const initialState = {
    product: initialValues,
    productAdded: false,
    fetchProductInfoStatus: LOADING_STATUS.IDLE,
    fetchProductRolesStatus: LOADING_STATUS.IDLE,
    fetchAllRolesStatus: LOADING_STATUS.IDLE,
    insertStatus: LOADING_STATUS.IDLE,
    insertRoles: LOADING_STATUS.IDLE,
    error: null,
};

export const fetchProductInfo = createAsyncThunk(
    'products/fetchProductInfo',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const data = await getProductInfo(id);
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo cargar el producto con el id ${id}.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const createSingleProduct = createAsyncThunk(
    'products/createSingleProduct',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const data = await createProduct(body);
            dispatch(
                enqueueSnackbar({
                    message: `Producto creado con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo crear el producto, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const fetchLandingProductInfo = createAsyncThunk(
    'products/fetchLandingProductInfo',
    async (name, { dispatch, rejectWithValue }) => {
        try {
            const data = await getLandingProductInfo(name);
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo cargar el producto.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const updateSingleProduct = createAsyncThunk(
    'products/updateSingleProduct',
    async ({ id, body }, { dispatch, rejectWithValue }) => {
        try {
            const data = await updateProduct(id, body);
            dispatch(
                enqueueSnackbar({
                    message: `Producto actualizado con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo actualizar el producto, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const uploadProductPicture = createAsyncThunk(
    'products/updateProductPicture',
    async ({ id, body }, { dispatch, rejectWithValue }) => {
        try {
            const data = await uploadImageProduct(id, body);
            dispatch(
                enqueueSnackbar({
                    message: `Imagen actualizada con éxito.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                    },
                })
            );
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo actualizar la Imagen, intente más tarde.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const singleProductSlice = createSlice({
    name: 'singleProduct',
    initialState,
    reducers: {
        resetValues: () => {
            return initialState;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchProductInfo.pending, state => {
                state.fetchProductInfoStatus = LOADING_STATUS.LOADING;
            })
            .addCase(fetchProductInfo.fulfilled, (state, { payload }) => {
                state.fetchProductInfoStatus = LOADING_STATUS.SUCCEEDED;
                state.product.productInfo = productMapper(payload);
            })
            .addCase(fetchProductInfo.rejected, (state, { error }) => {
                state.fetchProductInfoStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(fetchLandingProductInfo.pending, state => {
                state.fetchProductInfoStatus = LOADING_STATUS.LOADING;
            })
            .addCase(
                fetchLandingProductInfo.fulfilled,
                (state, { payload }) => {
                    state.fetchProductInfoStatus = LOADING_STATUS.SUCCEEDED;
                    state.product.productInfo = productMapper(payload);
                }
            )
            .addCase(fetchLandingProductInfo.rejected, (state, { error }) => {
                state.fetchProductInfoStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(createSingleProduct.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(createSingleProduct.fulfilled, state => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(createSingleProduct.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(updateSingleProduct.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(updateSingleProduct.fulfilled, state => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(updateSingleProduct.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(uploadProductPicture.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(uploadProductPicture.fulfilled, state => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(uploadProductPicture.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
    },
});

export const { resetValues } = singleProductSlice.actions;

export default singleProductSlice.reducer;
