import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    getAllCatalogs,
    getCatalog,
    getSubjectType,
    getRoles,
    getMenusForHome,
    getCourseModalities,
    getBranches,
    getSubjectDocumentType,
    getPaymentStatus,
    getPaymentTypes,
    getGenders,
    getAdoptions,
    getEmployees,
    getClients,
    getUserStatus,
    getAppointmentStatus,
    getCategories,
} from '../../api/catalogs';
import { LOADING_STATUS } from '../../helpers/constants';
import { decodeValue } from '../../helpers/strings';
import { enqueueSnackbar } from '../pushNotifications/pushNotificationsSlice';
import {
    formatBranches,
    formatMenusForHome,
    formatModalities,
    formatPaymentStatus,
    formatPaymentTypes,
    formatSubjectDocumentType,
} from './utils';

const initialState = {
    catalogs: {
        roles: [],
        genders: [],
        adoptions: [],
        employees: [],
        clients: [],
        userStatus: [],
        appointmentStatus: [],
        trainerList: [],
    },
    status: LOADING_STATUS.IDLE,
    error: null,
};

export const fetchSubjectTypes = createAsyncThunk(
    'catalogs/fetchSubjectTypes',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const data = await getSubjectType();
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo cargar los tipos de materias.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);
export const fetchCourseModalities = createAsyncThunk(
    'catalogs/fetchCourseModalities',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const data = await getCourseModalities();
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo cargar las modalidades del curso.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const fetchBranches = createAsyncThunk(
    'catalogs/fetchBranches',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const data = await getBranches();
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudieron cargar las sucursales.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const fetchRoles = createAsyncThunk(
    'catalogs/fetchRoles',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const data = await getRoles();
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudieron cargar los roles.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const fetchCatalogs = createAsyncThunk(
    'catalogs/fetchCatalogs',
    async (catalogName, { dispatch, rejectWithValue }) => {
        try {
            const data = await getCatalog(catalogName);
            return { catalogName, data };
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo cargar el catálogo ${catalogName}.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const fetchMenusForHome = createAsyncThunk(
    'catalogs/fetchMenusForHome',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const data = await getMenusForHome();
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudieron cargar los menus.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const fetchSubjectDocumentType = createAsyncThunk(
    'catalogs/fetchSubjectDocumentType',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const data = await getSubjectDocumentType();
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudieron cargar los tipos de documentos.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const fetchPaymentStatus = createAsyncThunk(
    'catalogs/fetchPaymentStatus',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const data = await getPaymentStatus();
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudieron cargar los status de pagos.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const fetchPaymentTypes = createAsyncThunk(
    'catalogs/fetchPaymentTypes',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const data = await getPaymentTypes();
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudieron cargar los tipos de pagos.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const fetchAllCatalogs = createAsyncThunk(
    'catalogs/fetchAllCatalogs',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const roles = await getRoles();
            const genders = await getGenders();
            const adoptions = await getAdoptions();
            const employees = await getEmployees();
            const userStatus = await getUserStatus();
            const categories = await getCategories();
            let data = {
                roles: [...roles],
                genders: [...genders],
                adoptions: [...adoptions],
                employees: [...employees],
                categories: [...categories],
                user_status: [...userStatus],
            };
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message:
                        'No se pudieron cargar los catálogos, algunos campos podrían mostrarse incorrectamente.',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const fetchAppointmentsCatalog = createAsyncThunk(
    'catalogs/fetchAppointmentsCatalog',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const data = await getClients();
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: 'No se pudo cargar el catalogo de clientes',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const fetchAppointmentsStatusCatalog = createAsyncThunk(
    'catalogs/fetchAppointmentsStatusCatalog',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const data = await getAppointmentStatus();
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: 'No se pudo cargar el catalogo de status',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

const catalogsSlice = createSlice({
    name: 'catalogs',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchPaymentTypes.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(fetchPaymentTypes.fulfilled, (state, { payload }) => {
                state.status = LOADING_STATUS.SUCCEEDED;
                state.catalogs.paymentTypes = formatPaymentTypes(payload);
            })
            .addCase(fetchPaymentTypes.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(fetchPaymentStatus.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(fetchPaymentStatus.fulfilled, (state, { payload }) => {
                state.status = LOADING_STATUS.SUCCEEDED;
                state.catalogs.paymentStatus = formatPaymentStatus(payload);
            })
            .addCase(fetchPaymentStatus.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(fetchSubjectDocumentType.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(
                fetchSubjectDocumentType.fulfilled,
                (state, { payload }) => {
                    state.status = LOADING_STATUS.SUCCEEDED;
                    state.catalogs.subjectDocumentType = formatSubjectDocumentType(
                        payload
                    );
                }
            )
            .addCase(fetchSubjectDocumentType.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(fetchMenusForHome.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(fetchMenusForHome.fulfilled, (state, { payload }) => {
                state.status = LOADING_STATUS.SUCCEEDED;
                state.catalogs.menusForHome = formatMenusForHome(payload);
            })
            .addCase(fetchMenusForHome.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(fetchSubjectTypes.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(fetchSubjectTypes.fulfilled, (state, { payload }) => {
                state.status = LOADING_STATUS.SUCCEEDED;
                state.catalogs.subjectType = payload;
            })
            .addCase(fetchSubjectTypes.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(fetchCourseModalities.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(fetchCourseModalities.fulfilled, (state, { payload }) => {
                state.status = LOADING_STATUS.SUCCEEDED;
                state.catalogs.courseModalities = formatModalities(payload);
            })
            .addCase(fetchCourseModalities.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(fetchBranches.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(fetchBranches.fulfilled, (state, { payload }) => {
                state.status = LOADING_STATUS.SUCCEEDED;
                state.catalogs.branches = formatBranches(payload);
            })
            .addCase(fetchBranches.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(fetchRoles.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(fetchRoles.fulfilled, (state, { payload }) => {
                state.status = LOADING_STATUS.SUCCEEDED;
                state.catalogs.roles = payload;
            })
            .addCase(fetchRoles.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(fetchCatalogs.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(fetchCatalogs.fulfilled, (state, { payload }) => {
                state.status = LOADING_STATUS.SUCCEEDED;
                state.catalogs[payload.catalogName] = payload.data;
            })
            .addCase(fetchCatalogs.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(fetchAllCatalogs.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(fetchAllCatalogs.fulfilled, (state, { payload }) => {
                state.status = LOADING_STATUS.SUCCEEDED;
                state.catalogs = Object.entries(payload).reduce(
                    (acc, [key, value]) => ({
                        ...acc,
                        [key.toLowerCase()]:
                            value?.length >= 0
                                ? value
                                      .filter(({ text }) => text)
                                      .map(value => ({
                                          ...value,
                                          text: decodeValue(value.text),
                                      }))
                                : value,
                    }),
                    {}
                );
            })
            .addCase(fetchAllCatalogs.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(fetchAppointmentsStatusCatalog.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(
                fetchAppointmentsStatusCatalog.fulfilled,
                (state, { payload }) => {
                    state.status = LOADING_STATUS.SUCCEEDED;
                    state.catalogs.appointmentStatus = payload;
                }
            )
            .addCase(
                fetchAppointmentsStatusCatalog.rejected,
                (state, { error }) => {
                    state.status = LOADING_STATUS.FAILED;
                    state.error = error.message;
                }
            )
            .addCase(fetchAppointmentsCatalog.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(
                fetchAppointmentsCatalog.fulfilled,
                (state, { payload }) => {
                    state.status = LOADING_STATUS.SUCCEEDED;
                    state.catalogs.clients = payload;
                }
            )
            .addCase(fetchAppointmentsCatalog.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
    },
});

export default catalogsSlice.reducer;
