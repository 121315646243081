import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { enqueueSnackbar } from '../../pushNotifications/pushNotificationsSlice';
import { LOADING_STATUS } from '../../../helpers/constants';
import { getUserLandingList, getUserList } from '../../../api/user';
import { formatUsers, getFilterData } from './utils';

const initialState = {
    data: {
        filters: {
            usersNameOptions: {
                selected: '',
                options: [],
            },
            usersStatusOptions: {
                selected: '',
                options: [],
            },
        },
        rows: [],
    },
    status: LOADING_STATUS.IDLE,
    error: null,
};

export const fetchLandingUsers = createAsyncThunk(
    'users/fetchLandingUserList',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const data = await getUserLandingList();
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message:
                        'No se pudo cargar la lista de entrenadores, por favor refresque la página. Si el problema persiste contacte a soporte.',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const fetchUsers = createAsyncThunk(
    'users/fetchUserList',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const data = await getUserList();
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message:
                        'No se pudo cargar la lista de usuarios, por favor refresque la página. Si el problema persiste contacte a soporte.',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        changeOption: (state, { payload }) => {
            state.data.filters[payload.name].selected = payload.value;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchUsers.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(fetchUsers.fulfilled, (state, { payload }) => {
                const cleanUsers = formatUsers(payload);

                state.status = LOADING_STATUS.SUCCEEDED;
                state.data.rows = cleanUsers;
                state.data.filters = getFilterData(cleanUsers);
            })
            .addCase(fetchUsers.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(fetchLandingUsers.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(fetchLandingUsers.fulfilled, (state, { payload }) => {
                const cleanUsers = formatUsers(payload);

                state.status = LOADING_STATUS.SUCCEEDED;
                state.data.rows = cleanUsers;
                state.data.filters = getFilterData(cleanUsers);
            })
            .addCase(fetchLandingUsers.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
    },
});

export const { changeOption } = usersSlice.actions;

export default usersSlice.reducer;
