export const formatProducts = productsArray =>
    productsArray.map(product => {
        return {
            id: product.id,
            image: product.image ?? null,
            product: product.name ?? '',
            description: product.description ?? '',
            category: product?.category?.text ?? '',
            supplierPrice: product.supplierPrice ?? null,
            clientPrice: product.clientPrice ?? null,
            existence: product.existence ?? null,
        };
    });

export const getFilterData = products => {
    const productsOptions = {
        selected: '',
        options: Array.from(
            products.reduce(
                (acc, cur) => (cur.product ? acc.add(cur.product) : acc),
                new Set()
            )
        ),
    };

    const productsCategoryOptions = {
        selected: '',
        options: Array.from(
            products.reduce(
                (acc, cur) => (cur.category ? acc.add(cur.category) : acc),
                new Set()
            )
        ),
    };

    return {
        productsOptions,
        productsCategoryOptions,
    };
};
