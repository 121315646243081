import PageHeader from '../../../common/PageHeader/PageHeader';
import { Button } from '@material-ui/core';
import ExportButton from '../../../common/ExportButton/ExportButton';
import { Link } from 'react-router-dom';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import ProductsFilters from './productsFilters/ProductsFilters';
import ProductsTable from './productsTable/ProductsTable';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from './ProductsSlice';

// cards
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(theme => ({
    downloadButton: {
        backgroundColor: green[600],
        color: 'white',
        '&:hover': {
            backgroundColor: green[800],
        },
    },
    topButton: {
        '&:not(:last-child)': {
            marginRight: theme.spacing(2),
        },
    },
    //
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: theme.spacing(8, 0, 6),
        placeItems: 'center',
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
        width: 400,
    },
    cardContent: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    root: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        flexGrow: 1,
    },
    cover: {
        width: '300px',
        height: '300px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '300px',
        },
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    rootMobile: {
        display: 'none',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    tableLink: {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    description: {
        whiteSpace: 'pre-wrap',
    },
}));

const csvHeaders = [
    { label: 'Servicios', key: 'product' },
    { label: 'Email', key: 'email' },
    { label: 'Nombre', key: 'name' },
    { label: 'Apellido', key: 'lastName' },
    { label: 'Status del usuario', key: 'productsStatus' },
];

const Products = () => {
    const classes = useStyles();
    const status = useSelector(state => state.products.status);
    const filters = useSelector(state => state.products.data.filters);
    const rows = useSelector(state => state.products.data.rows);

    const filtersActive = Object.values(filters).some(
        ({ selected }) => selected
    );

    const filteredRows = ((filtersActive, rows) => {
        if (!filtersActive) return rows;
        let newRows = [];
        if (filters.productsOptions.selected) {
            const filterItems = query => {
                return rows.filter(
                    row =>
                        row.product.toLowerCase().indexOf(query.toLowerCase()) >
                        -1
                );
            };
            newRows = filterItems(filters.productsOptions.selected);
        }
        if (filters.productsCategoryOptions.selected) {
            newRows = (newRows.length > 0 ? newRows : rows).filter(
                row => row.category === filters.productsCategoryOptions.selected
            );
        }

        return newRows;
    })(filtersActive, rows);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchProducts());
    }, [dispatch]);

    return (
        <div>
            <PageHeader title="Productos">
                <Button
                    className={classes.topButton}
                    color="primary"
                    component={Link}
                    to="/products/create"
                    variant="contained"
                >
                    Adicionar
                </Button>
            </PageHeader>

            <ProductsFilters {...filters}></ProductsFilters>
            <ProductsTable rows={filteredRows} status={status}></ProductsTable>
        </div>
    );
};

export default Products;
