import axios from 'axios';
import { axiosConfig } from './axiosConfig';

const axiosConfigCatalogs =
    process.env.REACT_APP_ENV === 'development'
        ? axiosConfig('clients-api')
        : axiosConfig('client.sigrha.com');

export const getCatalog = async catalogName => {
    const { data } = await axios.get('/catalogs/catalogName', {
        params: { catalogName },
        ...axiosConfigCatalogs,
    });

    return data;
};

export const getAllCatalogs = async () => {
    const { data } = await axios.get('allCatalogs', axiosConfig(''));

    return data;
};

export const getSubjectType = async () => {
    const { data } = await axios.get('subjectType', axiosConfig('api'));

    return data;
};

export const getCourseModalities = async () => {
    const { data } = await axios.get('CourseModalities', axiosConfig('api'));

    return data;
};

export const getBranches = async () => {
    const { data } = await axios.get('branches', axiosConfig('api'));

    return data;
};

export const getRoles = async () => {
    const { data } = await axios.get('catalogs/roles', axiosConfig('api/v1'));

    return data;
};

export const getGenders = async () => {
    const { data } = await axios.get('catalogs/genders', axiosConfig('api/v1'));

    return data;
};

export const getCategories = async () => {
    const { data } = await axios.get(
        'catalogs/categories',
        axiosConfig('api/v1')
    );

    return data;
};

export const getAdoptions = async () => {
    const { data } = await axios.get(
        'catalogs/adoptions',
        axiosConfig('api/v1')
    );

    return data;
};

export const getEmployees = async () => {
    const { data } = await axios.get(
        'employees/catalog',
        axiosConfig('api/v1')
    );

    return data;
};

export const getClients = async () => {
    const { data } = await axios.get('clients/catalog', axiosConfig('api/v1'));

    return data;
};

export const getUserStatus = async () => {
    const { data } = await axios.get(
        'catalogs/userStatus',
        axiosConfig('api/v1')
    );

    return data;
};

export const getAppointmentStatus = async () => {
    const { data } = await axios.get(
        'catalogs/appointmentStatus',
        axiosConfig('api/v1')
    );

    return data;
};

export const getTrainersList = async id => {
    const { data } = await axios.get(
        `/clients/trainers/${id}`,
        axiosConfig('api/v1')
    );
    return data;
};

export const getMenusForHome = async () => {
    const { data } = await axios.get(
        'subjects/GetSubjectList',
        axiosConfig('api')
    );

    return data;
};

export const getSubjectDocumentType = async () => {
    const { data } = await axios.get('subjectDocumentType', axiosConfig('api'));
    return data;
};

export const getPaymentStatus = async () => {
    const { data } = await axios.get('paymentStatus', axiosConfig('api'));
    return data;
};

export const getPaymentTypes = async () => {
    const { data } = await axios.get('paymentTypes', axiosConfig('api'));
    return data;
};
