import React, { useRef, useState } from 'react';
import { useParams } from 'react-router';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CheckIcon from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { OutlinedTextField } from '../../common/FormComponents/FormComponents';
import {
    initialChangePasswordValues,
    validationChangePasswordSchema,
} from './mainForm';
import { useDispatch } from 'react-redux';
import { FastField, Form, Formik, getIn } from 'formik';
import { enqueueSnackbar } from '../pushNotifications/pushNotificationsSlice';
import { changePassword } from '../../api/auth';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function ChangePassword() {
    const { token } = useParams();
    const classes = useStyles();
    const prevSubmitCount = useRef(0);
    const [changed, setChanged] = useState(false);
    const dispatch = useDispatch();

    const handleSubmit = async values => {
        try {
            const body = { newPassword: values.password, token: token };
            await changePassword(body);
            setChanged(true);
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: 'No se pudo cambiar la contraseña.',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
        }
    };

    const handleFieldUpdate = (nextProps, currentProps) => {
        if (
            nextProps.edit !== currentProps.edit ||
            nextProps.disabled !== currentProps.disabled ||
            nextProps.loading !== currentProps.loading ||
            nextProps.catalog !== currentProps.catalog ||
            nextProps.name !== currentProps.name ||
            getIn(nextProps.formik.values, currentProps.name) !==
                getIn(currentProps.formik.values, currentProps.name) ||
            getIn(nextProps.formik.errors, currentProps.name) !==
                getIn(currentProps.formik.errors, currentProps.name) ||
            getIn(nextProps.formik.touched, currentProps.name) !==
                getIn(currentProps.formik.touched, currentProps.name) ||
            Object.keys(currentProps).length !==
                Object.keys(nextProps).length ||
            nextProps.formik.isSubmitting !== currentProps.formik.isSubmitting
        ) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            {!changed ? (
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5" align="center">
                        Cambio de contraseña
                    </Typography>

                    <Formik
                        enableReinitialize
                        className={classes.form}
                        validationSchema={validationChangePasswordSchema}
                        initialValues={initialChangePasswordValues}
                        onSubmit={handleSubmit}
                    >
                        {props => {
                            const { errors, submitCount, values } = props;
                            if (submitCount !== prevSubmitCount.current) {
                                prevSubmitCount.current = submitCount;
                                if (Object.keys(errors).length > 0) {
                                    dispatch(
                                        enqueueSnackbar({
                                            message:
                                                'Por favor llene todos los campos correctamente.',
                                            options: {
                                                key:
                                                    new Date().getTime() +
                                                    Math.random(),
                                                variant: 'error',
                                            },
                                        })
                                    );
                                }
                            }
                            return (
                                <Form>
                                    <Grid container spacing={3}>
                                        <FastField
                                            component={OutlinedTextField}
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            name="password"
                                            type="password"
                                            edit={true}
                                            disabled={false}
                                            label="nueva contraseña"
                                            shouldUpdate={handleFieldUpdate}
                                        />
                                        <FastField
                                            component={OutlinedTextField}
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            name="confirmPassword"
                                            type="password"
                                            edit={true}
                                            disabled={false}
                                            label="repetir contraseña"
                                            shouldUpdate={handleFieldUpdate}
                                        />
                                    </Grid>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="default"
                                        className={classes.submit}
                                    >
                                        Aceptar
                                    </Button>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            ) : (
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <CheckIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5" align="center">
                        La contraseña ha sido cambiada
                    </Typography>
                </div>
            )}
        </Container>
    );
}
