import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import userIcon from '../../assets/images/user-icon.png';
import Loading from '../Loading/Loading';
import { CardActionArea } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        backgroundPosition: 'center',
        paddingBottom: theme.spacing(8),
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0px 5px 5px 5px',
        height: 240,
        minWidth: 240,
    },
    cardMedia: {
        backgroundPosition: 'center',
        justifyContent: 'center',
        alignItems: 'flex-end',
        height: 240,
        minWidth: 240,
        [theme.breakpoints.down('md')]: {},
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    button: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        border: 'none',
    },
    inputFile: {
        width: 0,
        height: 0,
    },
    landing: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        border: 'none',
        width: '100%',
        height: 240,
        background:
            'linear-gradient(360deg, rgba(0,0,0,1), rgba(0,0,0,0.3), rgba(0,0,0,0))',
        margin: 0,
    },
}));

export default function CardMediaComponent({
    handleFileChange,
    loading,
    image = '',
    title = '',
    edit = false,
    isLanding = false,
    onClick,
}) {
    const classes = useStyles();
    const [fileChanged, setFileChanged] = useState(null);
    const [newFile, setNewFile] = useState();

    const convertBase64 = file => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = error => {
                reject(error);
            };
        });
    };

    const handleDialogFile = async e => {
        const file = e?.target?.files[0];
        if (file !== undefined) {
            const split = file?.name?.split('.');
            const newFileName = title.replace(/ /g, '').toLowerCase();
            const base64 = await convertBase64(file);
            let copiedFile = new File([file], `${newFileName}.jpg`, {
                filename: file?.name,
                type: 'image/jpeg',
                lastModified: new Date(),
            });
            setFileChanged(base64);
            handleFileChange(copiedFile);
        }
    };

    return (
        <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card} onClick={() => onClick()}>
                {loading ? (
                    <Loading />
                ) : (
                    <CardActions
                        className={classes.cardMedia}
                        style={{
                            height: 240,
                            padding: 0,
                            backgroundImage: `url(${
                                fileChanged
                                    ? fileChanged
                                    : image?.length > 0
                                    ? image
                                    : userIcon
                            })`,
                            backgroundSize: 'cover',
                        }}
                    >
                        {isLanding ? (
                            <CardActionArea
                                component="a"
                                href="#"
                                variant="outlined"
                                className={classes.landing}
                            >
                                <Typography
                                    variant="h6"
                                    align="center"
                                    gutterBottom
                                >
                                    {title}
                                </Typography>
                            </CardActionArea>
                        ) : (
                            <>
                                <Button
                                    component="label"
                                    variant="contained"
                                    className={classes.button}
                                    size="small"
                                    color="primary"
                                >
                                    View
                                </Button>
                                {edit && (
                                    <Button
                                        component="label"
                                        variant="contained"
                                        className={classes.button}
                                        size="small"
                                        color="primary"
                                    >
                                        Change
                                        <input
                                            name="image"
                                            type="file"
                                            className={classes.inputFile}
                                            onChange={handleDialogFile}
                                        />
                                    </Button>
                                )}
                            </>
                        )}
                    </CardActions>
                )}
            </Card>
        </Grid>
    );
}
